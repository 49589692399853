<template>
  <div>
    <h2>Übersicht: Shop Artikel</h2>
    <div>
      <button @click="refreshData">
        <icon-refresh />
      </button>
      <button class="ma-2" @click="createProduct">
        Artikel anlegen
      </button>
    </div>
    <div>
      <input v-model="search" type="text" placeholder="Suche Artikel" />
    </div>
    <table>
      <thead>
        <tr>
          <td v-for="header in headers" :key="header.text">
            {{ header.text }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in products" :key="product.productNumber">
          <td>
            {{ product.productNumber }}
          </td>
          <td>
            {{ product.name }}
          </td>
          <td>
            {{ product.calculatedPrice.totalPrice }}
          </td>
          <td>
            {{ product.stock }}
          </td>
          <td>
            {{ product.manufacturerNumber }}
          </td>
          <td>
            {{ product.active }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import IconRefresh from "@/components/icons/IconRefresh";

export default {
  name: "Products",
  components: { IconRefresh },
  data() {
    return {
      products: [],
      search: "",
      headers: [
        { text: "Artikelnummer", value: "productNumber" },
        {
          text: "Artikel",
          sortable: false,
          value: "name"
        },
        { text: "Preis (brutto)", value: "calculatedPrice.totalPrice" },
        { text: "Auf Lager", value: "stock" },
        { text: "Lieferanten-Nr.", value: "manufacturerNumber" },
        { text: "Aktiv", value: "active" }
      ]
    };
  },
  methods: {
    refreshData() {
      this.loadProducts();
      this.$emit("rehydrate data");
    },
    createProduct() {
      alert("HELLO PRODUCT");
    },
    showAlert(a) {
      alert("Alert! \n" + a.name);
    },
    loadProducts() {
      axios
        .get(`${process.env.VUE_APP_SHOP_URL}/sales-channel-api/v3/product`, {
          headers: {
            "sw-access-key": process.env.VUE_APP_SW_ACCESS_KEY
          }
        })
        .then(response => {
          this.products = response.data.data;
        });
    }
  },
  mounted() {
    this.loadProducts();
  }
};
</script>

<style lang="scss">
tbody {
  tr {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
